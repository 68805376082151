<template>
  <div>
    <a-modal
      v-model="visibleParentSuggestion"
      :footer="null"
      :mask="false"
      :height="500"
      :dialog-style="{
        top: '20px',
      }"
      :ok-button-props="{ props: { disabled: true } }"
      :cancel-button-props="{ props: { disabled: true } }"
    >
      <label class="typo__label">{{
        $t("profil.parentSuggestionTitle")
      }}</label>
      <multiselect
        v-model="selectedSuggestionParent"
        :placeholder="$t('recette.selectionnerParent')"
        :selectLabel="$t('sms.pressEnterToSelect')"
        :options="suggestionParentList"
        :custom-label="
          ({ name }) => {
            return name;
          }
        "
        :close-on-select="false"
        :preserve-search="true"
        :maxHeight="700"
        label="_id"
        track-by="_id"
        :preselect-first="true"
        :multiple="false"
      >
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="align-text-top">
              {{ props.option.name }}
            </span>
            <br />
            <span class="align-text-top">
              {{ $t("profil.tel") }}: {{ props.option.fatherPhone }} -
              {{ props.option.motherPhone }}
            </span>
            <br />
            <span class="option__small font-size-12"
              >{{ $t("inscription.enfant") }} :
              {{ props.option.children }}</span
            >
          </div>
        </template>

        <template slot="noOptions">
          {{ $t("sms.noOptions") }}
        </template>
      </multiselect>
      <a-divider class="mt-3 mb-2"></a-divider>
      <div style="margin-bottom: 30px">
        <a-button
          @click="visibleParentSuggestion = false"
          class="ml-2 mr-2"
          style="float: right"
          >{{ $t("all.cancel") }}</a-button
        >
        <a-button
          @click="handleAddParentSuggestion"
          :disabled="!selectedSuggestionParent"
          type="primary"
          style="float: right"
          >{{ $t("all.add") }}</a-button
        >
      </div>
    </a-modal>
    <a-modal
      v-model="visibleDeleteReasson"
      v-show="visibleDeleteReasson"
      :title="$t('all.enterInformationBelow')"
      :width="500"
    >
      <div class="row">
        <div class="col-lg-12">
          <a-form-item :label="$t('punition.raison')">
            <a-input
              :placeholder="$t('punition.raison')"
              type="textarea"
              v-model="deleteReason"
            />
          </a-form-item>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="visibleDeleteReasson = false">
          {{ $t("action.annuler") }}
        </a-button>
        <a-button key="submit" type="primary" @click="onDelete">
          {{ $t("all.okay") }}
        </a-button>
      </template>
    </a-modal>

    <a-page-header
      :title="$t('liste.listeEleves')"
      :sub-title="$t('liste.consulterProfil')"
    >
      <template slot="tags">
        <a-tag color="blue"> {{ $t("liste.administration") }} </a-tag>
      </template>
      <template slot="extra">
        <a-button type="primary" @click="showModal" key="2">
          <a-icon type="user-add" @click="showModal" />{{
            $t("liste.ajouterEleve")
          }}
        </a-button>
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item
              key="1"
              @click="generateBadges"
              :disabled="loadingBadges"
              :loading="loadingBadges"
            >
              <a-icon type="file-pdf" @click="generateBadges" />{{
                $t("action.imprimerBadges")
              }}
            </a-menu-item>
            <a-menu-item
              key="5"
              @click="generateNewBadges"
              :disabled="loadingBadges"
              :loading="loadingBadges"
            >
              <a-icon type="file-pdf" @click="generateNewBadges" />{{
                $t("action.imprimerNewBadges")
              }}
            </a-menu-item>
            <a-menu-item key="2" @click="generatePdf">
              <a-icon type="file-pdf" @click="generatePdf" />
              {{ $t("action.imprimerTab") }}
            </a-menu-item>
            <a-menu-item key="3" @click="openImport">
              <a-icon type="file-excel" @click="openImport" />
              {{ $t("action.importer") }}
            </a-menu-item>
            <a-menu-item key="4" @click="openArchive">
              <a-icon type="database" @click="openArchive" />
              {{ $t("liste.listeElevesArchive") }}
            </a-menu-item>
            <a-menu-item key="6" @click="generateLoginsParents">
              <a-icon
                type="file-pdf"
                @click="loadingBadges"
                :disabled="loadingBadges"
                :loading="loadingLogins"
              />
              Logins parents
            </a-menu-item>
          </a-menu>
          <a-button
            style="margin-left: 8px"
            :disabled="loadingBadges"
            :loading="loadingBadges"
          >
            {{ $t("action.actions") }} <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </template>
      <a-descriptions size="small" :column="3">
        <a-descriptions-item :label="$t('liste.nonInscrits')">
          {{ headerInscrit }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('liste.inscrits')">
          {{ headerNonInscrit }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('liste.parents')">
          {{ parentsCount }}
        </a-descriptions-item>
      </a-descriptions>
      <a-row type="flex">
        <a-statistic :title="$t('liste.total')" :value="filtredTable.length" />
        <a-statistic
          :title="$t('profil.fille')"
          prefix="%"
          :value="countFille"
          :style="{
            margin: '0 32px',
          }"
        />
        <a-statistic
          :title="$t('profil.garcon')"
          prefix="%"
          :value="countGarcon"
        />
      </a-row>
    </a-page-header>
    <div>
      <a-modal
        v-model="visibleData"
        v-show="visibleData"
        :title="$t('all.enterInformationBelow')"
        on-ok="addStudent"
        :width="1000"
      >
        <template slot="footer">
          <a-button
            key="back"
            :disabled="loadingData"
            @click="handleCancelModalAdd"
          >
            {{ $t("action.annuler") }}
          </a-button>
          <a-button
            key="submit"
            type="primary"
            :disabled="loadingData"
            @click="addStudent"
          >
            {{ $t("action.confirmer") }}
          </a-button>
        </template>
        <a-form :form="newStudentForm" class="container" @submit="addStudent">
          <div class="row">
            <div class="col-lg-4">
              <a-form-item :label="$t('liste.identifiantUnique')">
                <a-input
                  @change="debounceField"
                  :placeholder="
                    $t('liste.identifiantUnique') + ' (المعرف الوحيد)'
                  "
                  v-decorator="[
                    'uniqueId',
                    {
                      trigger: ``,
                    },
                  ]"
                />
              </a-form-item>
            </div>
            <div class="col-lg-4">
              <a-form-item :label="$t('profil.nom')">
                <a-input
                  @change="debounceField"
                  :placeholder="$t('profil.nom')"
                  v-decorator="[
                    'lastName',
                    {
                      trigger: ``,
                      rules: [
                        {
                          required: true,
                          message: $t('requis.nom'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
            <div class="col-lg-4">
              <a-form-item :label="$t('profil.prenom')">
                <a-input
                  @change="debounceField"
                  :placeholder="$t('profil.prenom')"
                  v-decorator="[
                    'firstName',
                    {
                      trigger: ``,
                      rules: [
                        {
                          required: true,
                          message: $t('requis.prenomPere'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <a-form-item :label="$t('profil.dateNaissance')">
                <a-date-picker
                  :placeholder="$t('profil.dateNaissance')"
                  format="DD-MM-YYYY"
                  :disabled-date="disabledDate"
                  v-decorator="[
                    'BirthDate',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.dateNaissance'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item :label="$t('profil.sexe')">
                <a-radio-group
                  :placeholder="$t('profil.sexe')"
                  v-decorator="[
                    'gender',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.genre'),
                        },
                      ],
                    },
                  ]"
                >
                  <a-radio value="male"> {{ $t("profil.garcon") }}</a-radio>
                  <a-radio value="female"> {{ $t("profil.fille") }} </a-radio>
                </a-radio-group>
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <a-form-item :label="$t('profil.adresse')">
                <a-input
                  :placeholder="$t('profil.adresse')"
                  @change="debounceField"
                  v-decorator="['adress', { trigger: `` }]"
                />
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item :label="$t('liste.telephone')">
                <a-input
                  :placeholder="$t('liste.telephone')"
                  type="number"
                  @change="debounceField"
                  v-decorator="[
                    'phone',
                    {
                      trigger: ``,
                      rules: [
                        {
                          len: 8,
                          message: $t('requis.telephone'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <a-form-item :label="$t('profil.notes')">
                <a-input
                  :placeholder="$t('profil.notes')"
                  type="textarea"
                  @change="debounceField"
                  v-decorator="[
                    'notes',
                    {
                      trigger: ``,
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <a-form-item :label="$t('liste.classes')">
                <a-select
                  show-search
                  :placeholder="$t('liste.classes')"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="[
                    'classRoom',
                    {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    },
                  ]"
                >
                  >
                  <a-select-option
                    :value="item.id"
                    v-for="item in classRoomList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item :label="$t('liste.parentsExis')">
                <a-select
                  :disabled="disabledSP"
                  show-search
                  :autoFocus="true"
                  :placeholder="$t('liste.parentsExis')"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="[
                    'parent',
                    {
                      rules: [
                        {
                          required: !newParent,
                          message: $t('requis.selection'),
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in parentsList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item :label="$t('liste.ajouterParent')">
                <a-switch @change="triggerAddNewParent" v-model="newParent">
                  <a-icon slot="checkedChildren" type="usergroup-add" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>
              </a-form-item>
            </div>
          </div>
          <a-card
            :title="$t('liste.ficheParents')"
            :bordered="true"
            class="span container"
            v-if="newParent"
          >
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.nomPere')">
                  <a-input
                    :placeholder="$t('liste.nomPere')"
                    @change="debounceField"
                    type="text"
                    v-decorator="[
                      'parentdata.father.firstName',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.nomPere'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.prenomPere')">
                  <a-input
                    @change="debounceField"
                    :placeholder="$t('liste.prenomPere')"
                    type="text"
                    v-decorator="[
                      'parentdata.father.lastName',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.prenomPere'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.nomMere')">
                  <a-input
                    @change="debounceField"
                    :placeholder="$t('liste.nomMere')"
                    type="text"
                    v-decorator="[
                      'parentdata.mother.firstName',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.nomMere'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.prenomMere')">
                  <a-input
                    @change="debounceField"
                    :placeholder="$t('liste.prenomMere')"
                    type="text"
                    v-decorator="[
                      'parentdata.mother.lastName',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.prenomMere'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.telPere')">
                  <a-input
                    :placeholder="$t('liste.telPere')"
                    type="number"
                    @change="
                      (val) => {
                        handleParentsSuggestions(val);
                        debounceField(val);
                      }
                    "
                    v-decorator="[
                      'parentdata.father.phone',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.telPere'),
                          },
                          {
                            len: 8,
                            message: $t('requis.telephone'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.telMere')">
                  <a-input
                    @change="
                      (val) => {
                        handleParentsSuggestions(val);
                        debounceField(val);
                      }
                    "
                    type="number"
                    :placeholder="$t('liste.telMere')"
                    v-decorator="[
                      'parentdata.mother.phone',
                      {
                        trigger: ``,
                        rules: [
                          {
                            required: true,
                            message: $t('requis.telMere'),
                          },
                          {
                            len: 8,
                            message: $t('requis.telephone'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <!--parents job-->
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.metierPere')">
                  <a-input
                    :placeholder="$t('liste.metierPere')"
                    @change="debounceField"
                    v-decorator="[
                      'parentdata.father.job',
                      {
                        trigger: ``,
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.metierMere')">
                  <a-input
                    @change="debounceField"
                    :placeholder="$t('liste.metierMere')"
                    v-decorator="[
                      'parentdata.mother.job',
                      {
                        trigger: ``,
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.adresseParents')">
                  <a-input
                    :placeholder="$t('liste.adresseParents')"
                    type="text"
                    @change="debounceField"
                    v-decorator="[
                      'parentdata.adress',
                      {
                        trigger: ``,
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item :label="$t('liste.emailParents')">
                  <a-input
                    :placeholder="$t('liste.emailParents')"
                    type="text"
                    @change="debounceField"
                    v-decorator="[
                      'parentdata.email',
                      {
                        trigger: ``,
                        rules: [
                          {
                            type: 'email',
                            message: $t('requis.email'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
          </a-card>
        </a-form>
      </a-modal>
    </div>
    <div>
      <a-table
        @change="tableChanged"
        :loading="tableLeading"
        :pagination="true"
        :data-source="data"
        :columns="columns"
        :rowKey="'_id'"
      >
        <template slot="firstName" slot-scope="text, record">
          <div key="firstName" @click="() => openProfile(record.id)">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="(e) => handleChange(e.target.value, record.id, col)"
            />
            <template v-else>
              <a-avatar
                size="large"
                v-if="record.photo"
                :src="settings.base_url + '/' + record.photo"
              />
              <a-avatar
                size="large"
                style="color: #f56a00; background-color: #fde3cf"
                v-else
              >
                {{ record.firstName[0].toUpperCase() }}
              </a-avatar>
              <a-button size="small" type="link" class="ml-2">
                {{ text + " " + record.lastName }}
              </a-button>
            </template>
          </div>
        </template>
        <template
          v-for="col in ['ficheparent', 'attestationPresence']"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="(e) => handleChange(e.target.value, record.id, col)"
            />
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <!--
        <a-select
          slot="scolarite"
          slot-scope="text, record"
          show-search
          :autoFocus="true"
          placeholder="Les class"
          option-filter-prop="children"
          :filter-option="filterOption"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          :default-value="record.classRoom ? record.classRoom._id : ''"
        >
          <a-select-option
            :value="item.id"
            v-for="item in classRoomList"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
-->
        <span slot="scolarite" slot-scope="text">
          <a-tag
            :key="text"
            :color="
              text === 'NON INSCRIT' || text === 'غير مسجل'
                ? 'volcano'
                : 'green'
            "
          >
            {{ text.toUpperCase() }}
          </a-tag>
        </span>
        <a-button
          slot="ficheparent"
          slot-scope="text, record"
          @click="() => parentFile(record)"
        >
          <a-icon type="select" /> {{ $t("action.afficher") }}
        </a-button>
        <a-button
          slot="attestationPresence"
          slot-scope="text, record"
          :disabled="
            record.scolarite === 'NON INSCRIT' ||
            record.scolarite === 'غير مسجل'
          "
          @click="
            () => {
              attestationPresence(record);
            }
          "
        >
          <a-icon type="file-pdf" /> {{ $t("action.imprimer") }}
        </a-button>
        <a-button
          slot="inscription"
          slot-scope="text, record"
          @click="() => attestationInscriptionPDF(record)"
        >
          <a-icon type="file-pdf" /> {{ $t("action.imprimer") }}
        </a-button>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
        <template slot="delete" slot-scope="text, record">
          <a-button-group>
            <a-popconfirm
              :title="$t('requis.supp2')"
              @confirm="() => deleteStudent(record.id)"
              v-if="verifyAccess(['all', 'suppression_eleve'])"
            >
              <a-button type="danger" size="small" icon="delete">
                {{ $t("action.supprimer") }}
              </a-button>
            </a-popconfirm>
            <a-button
              v-else
              type="danger"
              :disabled="true"
              size="small"
              icon="delete"
            >
              {{ $t("action.supprimer") }}
            </a-button>
            <a-popconfirm
              :title="$t('requis.annu')"
              @confirm="() => onCancelInscription(record.id)"
              v-if="
                verifyAccess(['all', 'annulation_eleve']) &&
                record.scolarite.toLowerCase() !== 'non inscrit'
              "
            >
              <a-button type="danger" size="small" icon="user-delete">
                {{ $t("action.annuler") }}
              </a-button>
            </a-popconfirm>
            <a-button
              v-else
              type="danger"
              size="small"
              icon="user-delete"
              :disabled="true"
            >
              {{ $t("action.annuler") }}
            </a-button>
          </a-button-group>
        </template>
      </a-table>
    </div>
    <a-modal
      v-model="visibleParentFile"
      :title="$t('all.enterInformationBelow')"
      width="50%"
      :key="componentKey"
      centered
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancelModalParent">
          {{ $t("action.annuler") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loadingData"
          :disabled="loadingData"
          @click="updateParent"
        >
          {{ $t("action.confirmer") }}
        </a-button>
      </template>
      <div class="container">
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.nomPere") }}
            <a-input
              id="nomPere"
              type="text"
              name="nomPere"
              :default-value="activeParent.father.firstName"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.prenomPere") }}
            <a-input
              id="prenomPere"
              type="text"
              name="prenomPere"
              :default-value="activeParent.father.lastName"
            />
          </label>
        </span>
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.nomMere") }}
            <a-input
              id="nomMere"
              class="inp"
              type="text"
              name="nomMere"
              :default-value="activeParent.mother.firstName"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.prenomMere") }}
            <a-input
              id="prenomMere"
              class="inp"
              type="text"
              name="prenomMere"
              :default-value="activeParent.mother.lastName"
            />
          </label>
        </span>
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.telPere") }}
            <a-input
              id="telephonePere"
              type="text"
              name="telephonePere"
              :default-value="activeParent.father.phone"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.telMere") }}
            <a-input
              id="telephoneMere"
              class="inp"
              type="text"
              name="telephoneMere"
              :default-value="activeParent.mother.phone"
            />
          </label>
        </span>
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.metierPere") }}
            <a-input
              id="FonctionPere"
              type="text"
              name="FonctionPere"
              :default-value="activeParent.father.job"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.metierMere") }}
            <a-input
              id="FonctionMere"
              class="inp"
              type="text"
              name="FonctionMere"
              :default-value="activeParent.mother.job"
            />
          </label>
        </span>
        <span class="span row">
          <label class="col-md-6">
            {{ $t("liste.emailParents") }}
            <a-input
              id="emailParent"
              type="text"
              name="emailParent"
              :default-value="activeParent.email"
            />
          </label>
          <label class="col-md-6">
            {{ $t("liste.adresseParents") }}
            <a-input
              id="adressParent"
              class="inp"
              type="text"
              name="adressParent"
              :default-value="activeParent.adress"
            />
          </label>
        </span>
        <div class="row justify-content-center border-with-text">
          <span class="border-text">{{ $t("menu.liste")}}</span>
          <a-list
            :grid="{
              gutter: activeStudentlist.length > 4 ? 12 : 24,
              column:
                activeStudentlist.length > 4 ? 4 : activeStudentlist.length,
            }"
            item-layout="v"
            :data-source="activeStudentlist"
            :bordered="false"
          >
            <a-list-item slot="renderItem" slot-scope="item, index">
              <a-list-item-meta
                @click="() => openProfile(item.id)"
                :description="item.description"
              >
                <a slot="title" :class="index">{{ item.title }}</a>
                <a-avatar
                  size="large"
                  slot="avatar"
                  v-if="item.photo"
                  :src="settings.base_url + '/' + item.photo"
                />
                <a-avatar
                  size="large"
                  slot="avatar"
                  style="color: #f56a00; background-color: #fde3cf"
                  v-else
                >
                  {{ item.title[0].toUpperCase() }}
                </a-avatar>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="visibleAttestationPresence"
      :title="$t('all.enterInformationBelow')"
      :width="500"
      :key="componentKey"
    >
      <template slot="footer">
        <a-button
          key="back"
          @click="() => (this.visibleAttestationPresence = false)"
        >
          {{ $t("action.annuler") }}
        </a-button>
        <a-button
          type="primary"
          :loading="loadingData"
          :disabled="loadingData"
          @click="attestationPresencePDF(activeStudent)"
        >
          <img
            style="border-radius: 30%; margin-right: 5px"
            width="20"
            src="resources/images/flags/tunisia.svg"
            alt="Arabic"
          />
          {{ $t("action.imprimer") }}
        </a-button>
        <a-button
          type="primary"
          :loading="loadingData"
          :disabled="loadingData"
          @click="attestationPresenceFr(activeStudent)"
        >
          <img
            style="border-radius: 30%; margin-right: 5px"
            width="20"
            src="resources/images/flags/france.svg"
            alt="Arabic"
          />
          {{ $t("action.imprimer") }}
        </a-button>
      </template>
      <div>
        <span class="span">
          <label>
            {{ $t("liste.regionalEducation") }}
            <a-input
              id="cr"
              type="text"
              name="cr"
              :default-value="schoolDetails.delegeRegional"
            />
          </label>
        </span>
        <span class="span">
          <label>
            {{ $t("etablissement.directrice") }}
            <a-input
              id="dir"
              type="text"
              name="dir"
              :default-value="schoolDetails.headMaster"
            />
          </label>
        </span>
        <span class="span">
          <label>
            {{ $t("bulletin.nom") }}
            <a-input
              id="nomStudent"
              type="text"
              name="nomStudent"
              :default-value="
                activeStudent.firstName + ' ' + activeStudent.lastName
              "
            />
          </label>
        </span>
        <span class="span">
          <label>
            {{ $t("profil.dateNaissance") }}
            <a-form-item>
              <a-date-picker
                :placeholder="$t('profil.dateNaissance')"
                format="DD-MM-YYYY"
                v-model="attestationPresenceBirthdate"
                v-decorator="[
                  'BirthDate',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.dateNaissance'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </label>
        </span>
        <span class="span">
          <label>
            {{ $t("profil.classe") }}
            <a-input
              id="className"
              type="text"
              name="className"
              :default-value="activeStudent.scolarite"
            />
          </label>
        </span>

        <span class="span">
          <label>
            {{ $t("punition.raison") }}

            <a-input
              :placeholder="$t('punition.raison')"
              id="reason"
              name="reason"
              type="textarea"
              default-value="........................................"
              v-decorator="[
                'reason',
                {
                  trigger: ``,
                },
              ]"
            />
          </label>
        </span>

        <span class="span">
          <label>
            {{ $t("liste.recuEn") }}
            <a-input
              id="recuEn"
              type="text"
              name="recuEn"
              default-value="...................."
            />
          </label>
        </span>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import router from "@/router";
import $ from "jquery";
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import { mapState } from "vuex";
import "jspdf-autotable";
import { notification } from "ant-design-vue";
import _ from "lodash";
import moment from "moment";

import axios from "axios";

const { AwesomeQR } = require("awesome-qr");

function readbleDate(dateString) {
  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();
  return mm + "/" + dd + "/" + yyyy;
}
function parentFormater(parent) {
  return {
    name:
      parent.father.firstName +
      " " +
      parent.father.lastName +
      " et " +
      parent.mother.firstName +
      " " +
      parent.mother.lastName,
    children: parent.students ? parent.students.length : 0,
    motherPhone: parent.mother.phone,
    fatherPhone: parent.father.phone,
    id: parent._id,
  };
}

export default {
  created() {
    this.$watch(
      () => this.settings.schoolarYears,
      (newValue, oldValue) => {
        this.init();
      }
    );
    try {
      this.loadingLogins = true;
      this.init();
      this.loadingLogins = false;
    } catch (error) {
      console.error(
        "Failed on load ,error handling strategy...",
        error.message
      );
    }
  },
  data() {
    return {
      suggestionTimeout: null,
      loadingLogins: true,
      suggestionParentList: [],
      selectedSuggestionParent: undefined,
      visibleParentSuggestion: false,
      visibleDeleteReasson: false,
      deleteReason: "",
      schoolDetails: {},
      loadingBadges: false,
      componentKey: 0,
      disabledSP: false,
      activeBuilding: {},
      filtredTable: [],
      tableLeading: true,
      newStudentForm: this.$form.createForm(this),
      newStudent: {
        birthDate: null,
        gender: null,
        classRoom: null,
        parent: null,
      },
      classRoomList: [],
      newParent: false,
      addNewParent: false,
      parentsList: [],
      parentsCount: 0,
      activeParentChange: {},
      activeStudent: {},
      attestationPresenceBirthdate: "",
      activeParent: {
        father: {
          firstname: "",
          lastName: "",
          job: "",
          email: "",
        },
        mother: {
          firstname: "",
          lastName: "",
          job: "",
          email: "",
        },
        students: [
          {
            firstName: "",
            lastName: "",
            _id: "",
          },
        ],
      },
      activeStudentlist: [],
      editingKey: "",
      visibleParentFile: false,
      visibleAttestationPresence: false,
      data: [],
      cacheData: null,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      currentYear: "",
      value: 1,
      value1: 1,
      loadingData: false,
      visibleData: false,
      columns: [
        {
          title: this.$t("liste.nomPrenom"),
          dataIndex: "firstName",
          key: "firstName",
          width: 60,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "firstName",
          },
          onFilter: (value, record) =>
            record.firstName.toLowerCase().includes(value.toLowerCase()) ||
            record.lastName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("liste.scolarite"),
          dataIndex: "scolarite",
          key: "scolarite",
          width: 50,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "scolarite",
          },
          onFilter: (value, record) =>
            record.scolarite.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("liste.ficheParent"),
          dataIndex: "ficheparent",
          key: "parent",
          width: 50,
          scopedSlots: {
            customRender: "ficheparent",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) => {
            let father = record.parent[0].father;
            let mother = record.parent[0].mother;
            return (
              father.firstName +
              father.lastName +
              father.phone +
              mother.firstName +
              mother.lastName +
              mother.phone
            )
              .toLowerCase()
              .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("liste.attestation"),
          dataIndex: "attestationPresence",
          key: "attestationPresence",
          width: 50,
          scopedSlots: {
            customRender: "attestationPresence",
          },
        },
        {
          title: this.$t("liste.inscription"),
          dataIndex: "inscription",
          key: "inscription",
          width: 50,
          scopedSlots: {
            customRender: "inscription",
          },
        },
        {
          title: this.$t("liste.supprimerAnnuler"),
          dataIndex: "delete",
          width: 210,
          scopedSlots: { customRender: "delete" },
          fixed: "right",
        },
      ],
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
    headerInscrit: function () {
      return this.filtredTable.reduce(
        (a, v) => (v.scolarite === this.$t("liste.nonInscrit") ? a + 1 : a),
        0
      );
    },
    headerNonInscrit: function () {
      return (
        this.filtredTable.length -
        this.filtredTable.reduce(
          (a, v) => (v.scolarite === this.$t("liste.nonInscrit") ? a + 1 : a),
          0
        )
      );
    },
    countGarcon: function () {
      return (
        parseFloat(
          (
            (this.filtredTable.reduce(
              (a, v) => (v.gender === "male" ? a + 1 : a),
              0
            ) *
              100) /
            this.filtredTable.length
          ).toFixed(1)
        ) || 0
      );
    },
    countFille: function () {
      return (
        parseFloat(
          (
            (this.filtredTable.reduce(
              (a, v) => (v.gender === "female" ? a + 1 : a),
              0
            ) *
              100) /
            this.filtredTable.length
          ).toFixed(1)
        ) || 0
      );
    },
  },
  methods: {
    async init() {
      this.currentYear = this.settings.schoolarYears.find(
        (sc) => sc._id == this.settings.activeSchoolarYear
      ).name;
      this.activeBuilding = this.settings.image.find(
        (building) => building.dbName == this.settings.activeBuilding
      );
      this.schoolDetails = this.user.building.find(
        (el) => el.dbName == this.settings.activeBuilding
      );
      this.tableLeading = true;
      apiClient
        .post("/classrooms/filter", {
          query: {
            status: "active",
          },
          aggregation: {
            $project: {
              _id: 1,
              name: 1,
            },
          },
        })
        .then((res) => {
          this.classRoomList = res.data.map((item) => ({
            name: item.name,
            id: item._id,
          }));
        });
      apiClient
        .post("/parents/filter", {
          query: { status: "active" },
          aggregation: {
            $project: {
              father: { firstName: 1, lastName: 1, phone: 1 },
              mother: { firstName: 1, lastName: 1, phone: 1 },
              students: 1,
              status: 1,
            },
          },
        })
        .then((res) => {
          this.parentsList = res.data.map((item) => parentFormater(item));
          this.parentsCount = res.data.filter(function (item) {
            return item.status === "active" || !item.status;
          }).length;
        });
      apiClient
        .post("/students/filter", {
          query: {
            status: "active",
          },
          aggregation: [
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "parents",
                localField: "parent",
                foreignField: "_id",
                as: "parent",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classRoom",
              },
            },
            {
              $project: {
                _id: 1,
                parent: {
                  father: { $arrayElemAt: ["$parent.father", 0] },
                  mother: { $arrayElemAt: ["$parent.mother", 0] },
                  _id: { $arrayElemAt: ["$parent._id", 0] },
                  adress: 1,
                  email: 1,
                  students: 1,
                },
                classRoom: { $arrayElemAt: ["$classRoom.name", 0] },
                BirthDate: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                photo: 1,
                adress: 1,
                uniqueId: 1,
                schoolarYearsHistory: 1,
              },
            },
          ],
        })
        .then((res) => {
          this.data = res.data.map((item) => this.studentFormater(item));

          this.data.sort((a, b) => {
            let fullname_a = a.firstName + a.lastName;
            let fullname_b = b.firstName + b.lastName;
            return fullname_a.localeCompare(fullname_b);
          });

          this.cacheData = this.data;
          this.tableLeading = false;
          this.filtredTable = this.data;
        });
    },
    verifyAccess: function (accessRoles) {
      const existing = this.user.access;
      if (existing.all) return true;
      if (
        existing.liste_eleves === true ||
        (existing.liste_eleves &&
          existing.liste_eleves.length > 0 &&
          existing.liste_eleves.find((e) => accessRoles.includes(e)))
      )
        return true;
      else return false;
    },
    moment,
    debounceField: _.debounce(function (e) {
      let field = e.target.id;
      this.newStudentForm.setFieldsValue({
        [field]: e.target.value,
      });
      this.newStudentForm.validateFields([`${field}`]);
    }, 50),

    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    openImport() {
      router.push({ path: "/uploadeleves" }).catch((error) => {
        console.info(error);
      });
    },
    openArchive() {
      router.push({ path: "/archive_elevesliste" }).catch((error) => {
        console.info(error);
      });
    },
    openProfile(id) {
      router.push(`/eleve/${id}`).catch((error) => {
        console.info(error);
      });
    },
    studentFormater(student) {
      var modified = student;
      modified.BirthDate = readbleDate(modified.BirthDate);
      modified.createdAt = readbleDate(modified.createdAt);
      modified.id = student._id;
      return {
        ...modified,
        scolarite: student.classRoom
          ? student.classRoom
          : this.$t("liste.nonInscrit"),
      };
    },
    updateParent() {
      const id = this.activeParent._id;
      this.loadingData = true;
      const update = {
        father: {
          firstName: $("#nomPere").val(),
          lastName: $("#prenomPere").val(),
          job: $("#FonctionPere").val(),
          phone: $("#telephonePere").val(),
        },
        mother: {
          firstName: $("#nomMere").val(),
          lastName: $("#prenomMere").val(),
          job: $("#FonctionMere").val(),
          phone: $("#telephoneMere").val(),
        },
        email: $("#emailParent").val(),
        adress: $("#adressParent").val(),
      };
      apiClient
        .patch(`/parents/${id}`, { data: update })
        .then((res) => {
          this.data = this.data.map((student) =>
            student.parent[0]._id == id
              ? {
                  ...student,
                  parent: [
                    {
                      ...student.parent[0],
                      ...update,
                    },
                  ],
                }
              : student
          );
          this.parentsList = this.parentsList.map((parent) =>
            parent.id == id ? parentFormater({ ...update, _id: id }) : parent
          );
          this.$message.success(this.$t("success.parentMSA"));

          this.visibleParentFile = false;
          this.loadingData = false;
          //location.reload();
        })
        .catch(() => {
          this.visibleParentFile = false;
          this.loadingData = false;
          this.openNotificationWithIcon(
            "warning",
            this.$t("changementNonEffectue"),
            this.$t("champsVides")
          );
        });
    },
    triggerAddNewParent() {
      this.disabledSP = !this.disabledSP;
      // this.newParent = !this.newParent;
    },
    handleNewStudentParentOnChange(value) {
      this.newStudent.parent = value;
    },
    handleNewStudentDateOnChange(value) {
      this.newStudent.birthDate = value;
    },
    handleNewStudentClassRoomOnChange(value) {
      this.newStudent.classRoom = value;
    },
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    parentFile(id) {
      this.componentKey += 1;
      this.activeParent = JSON.parse(JSON.stringify(id.parent[0]));
      //  this.activeStudentlist = this.activeParent.students.map((el) => {
      //    var description;
      //    var student = this.data.find((item) => item._id == el);
      //    /*  if (student.status == "active")
      //      description = student.classRoom
      //        ? `Inscrit au "${student.classRoom.name}"`
      //        : "Ne pas encore inscrit";
      //    else description = "Cet élève a été supprimé"; */
      //    return {
      //      id: student._id,
      //      title: student.firstName + " " + student.lastName,

      //      photo: student.photo,
      //    };
      //  });
      this.activeStudentlist = this.activeParent.students.reduce(
        (toReturn, studentID) => {
          var student = this.data.find((item) => item._id == studentID);
          if (student)
            toReturn.push({
              id: student._id,
              title: student.firstName + " " + student.lastName,
              photo: student.photo,
            });
          return toReturn;
        },
        []
      );

      this.visibleParentFile = true;
    },
    attestationPresence(id) {
      if (id) {
        this.componentKey += 1;
        this.activeParent = JSON.parse(JSON.stringify(id.parent[0]));
        this.activeStudent = id;
        this.attestationPresenceBirthdate = id.BirthDate;
        this.activeStudentlist = this.activeParent.students.map((el) => {
          var student = this.data.find((item) => item._id == el);
          return {
            id: student._id,
            title: student.firstName + " " + student.lastName,

            photo: student.photo,
          };
        });

        this.visibleAttestationPresence = true;
      }
    },
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
        this.cacheData = this.data;
      }
    },
    save(id) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => id === item.id)[0];
      const targetCache = newCacheData.filter((item) => id === item.id)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
        const update = {
          firstName: target.firstName,
          lastName: target.lastName,
          phone: target.phone,
          adress: target.adress,
        };
        apiClient
          .patch(`/students/${id}`, { data: update })
          .then()
          .catch(() => {
            this.openNotificationWithIcon(
              "warning",
              this.$t("changementNonEffectue")
            );
          });
      }
      this.editingKey = "";
    },
    cancel(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => id === item.id)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },

    deleteStudent(id) {
      this.selectedStudentId = id;
      this.visibleDeleteReasson = true;
    },

    onDelete() {
      const id = this.selectedStudentId;
      this.visibleDeleteReasson = false;
      apiClient
        .delete(`/students/${id}`)
        .then((res) => {
          apiClient
            .patch(`/students/${id}`, {
              data: {
                reasonArchive: this.deleteReason,
              },
            })
            .then((res) => {
              const dataSource = [...this.data];
              this.data = dataSource.filter((item) => item.id !== id);
              this.cacheData = this.data;
              this.openNotificationWithIcon(
                "success",
                this.$t("success.eleveSupp")
              );
            })
            .catch((e) => this.$message.warning(this.$t("error.supp")));
        })
        .catch((e) => this.$message.warning(this.$t("error.supp")))
        .finally(() => {
          this.deleteReason = "";
        });
    },
    onCancelInscription(id) {
      apiClient
        .post(`/students/classroom/cancel/${id}`)
        .then((res) => {
          const dataSource = [...this.data];
          let objIndex = this.data.findIndex((obj) => obj.id == id);
          this.data[objIndex].scolarite = "Non inscrit";
          this.cacheData = this.data;
          this.openNotificationWithIcon(
            "success",
            this.$t("success.InscriptionAnnulee")
          );
        })
        .catch((e) => {
          this.$message.warning(e.response.data.error.message);
        });
    },
    async addStudent(e) {
      this.newStudentForm.setFields({
        [parent]: { errors: [] },
      });
      this.newStudentForm.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          this.loadingData = true;
          let student = values;
          student.schoolarYearsHistory = {};
          student.schoolarYearsHistory[this.settings.activeSchoolarYear] =
            values.classRoom ? values.classRoom : null;
          if (this.newParent) {
            const data = values.parentdata;
            await apiClient.put("/parents", { data }).then((res) => {
              const { parent } = res.data;
              delete student.parentdata;
              student.parent = parent._id;
              this.parentsList.push(parentFormater(parent));
            });
            this.newParent = false;
          }
          await apiClient
            .put("/students", student)
            .then((res) => {
              student._id = res.data._id;
              this.data.push(this.studentFormater(res.data));
              this.$message.success(this.$t("success.eleveAjout"));
            })
            .catch((e) => {
              console.error(e);
              this.$message.warning(this.$t("error.ajout"));
            })
            .finally(() => {
              this.newStudentForm.resetFields();
              this.visibleData = false;
              this.loadingData = false;
            });

          if (values.classRoom) {
            const activeSchoolarYear = this.settings.activeSchoolarYear;
            const schoolarYears = this.settings.schoolarYears;
            let newInscription = {};
            schoolarYears.forEach((elem) => {
              if (elem._id == activeSchoolarYear) {
                newInscription.end = elem.end;
                newInscription.start = elem.start;
              }
            });
            newInscription.student = student._id;
            apiClient
              .put(
                `/classrooms/${values.classRoom}/student/${student._id}`,
                newInscription
              )
              .then((res) => {
                let className = this.classRoomList.find(
                  (e) => e.id == values.classRoom
                );
                this.data = this.data.map((e) => {
                  if (e.id === student._id) {
                    e.scolarite =
                      className && className.name ? className.name : "--";
                  }
                  return e;
                });
              })
              .catch((e) => {
                console.error(e);
              });
          }
        }
      });
    },
    handleCancelModalAdd(e) {
      this.visibleData = false;
      this.addNewParent = false;
    },
    handleCancelModalParent(e) {
      this.visibleParentFile = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // Function to add a new page with sections
    addPageWithSections(pdf, index) {
      if (index > 0 && index % 4 === 0) {
        pdf.addPage();
      }
      // Define the page size (A4)
      const pageWidth = 210;
      const pageHeight = 297;

      // Define section dimensions
      const sectionWidth = pageWidth / 2;
      const sectionHeight = pageHeight / 2;
      // Draw section borders on the new page
      pdf.rect(0, 0, sectionWidth, sectionHeight);
      pdf.rect(sectionWidth, 0, sectionWidth, sectionHeight);
      pdf.rect(0, sectionHeight, sectionWidth, sectionHeight);
      pdf.rect(sectionWidth, sectionHeight, sectionWidth, sectionHeight);
    },
    // Function to add image to the top right corner of a section
    addImageToSection(pdf, x, y, width, height, imgData) {
      pdf.addImage(imgData, "JPEG", x, y, width, height);
    },
    /*const addQRCode = async (pdf, text, x, y, size) => {
          await new AwesomeQR({
            text: text,
            size: size,
            margin: 3,
            color: {
              dark: "#000000",
              light: "#ffffff",
            },
          })
            .draw()
            .then((dataURL) => {
              console.log("dataURL :", dataURL);
              pdf.addImage(dataURL, "JPEG", x, y, 10, 10);
            });
        };*/
    async drawSection(pdf, parent, index, logo, imgData, schoolName) {
      pdf.setPage(Math.floor(index / 4) + 1);
      // Define the page size (A4)
      const pageWidth = 210;
      const pageHeight = 297;

      // Define section dimensions
      const sectionWidth = pageWidth / 2;
      const sectionHeight = pageHeight / 2;
      // Calculate section coordinates
      const x = (index % 2) * sectionWidth;
      const y = (Math.floor(index / 2) % 2) * sectionHeight;
      //add building logo
      const imageWidth = 15;
      const imageHeight = 15;
      //add qrcode image

      const qrData = btoa(
        parent._id +
          "." +
          this.settings.activeBuilding +
          "." +
          this.settings.base_url
      );

      let students = parent.students
        .map((s) => {
          return s.fullName + " (" + s.classRoom.name + " )";
        })
        .join("\n");
      pdf.setFont("Amiri", "bold");

      pdf.setFontSize(15);

      pdf.text(`Informations de connexion`, x + 15, y + 15);
      pdf.setFont("Amiri", "normal");
      pdf.setFontSize(12);

      pdf.text(schoolName, x + 50, y + 20, "center");

      pdf.setFontSize(10);

      // Add content to the section
      pdf.text(
        `Père : ${this.capitalizeFirstLetter(parent.father)}`,
        x + 10,
        y + 32
      );
      pdf.text(
        `Mére: ${this.capitalizeFirstLetter(parent.mother)}`,
        x + 10,
        y + 40
      );
      pdf.text(`Les élèves associés : `, x + 10, y + 48);

      pdf.text(`${students}`, x + 40, y + 48);
      const textChildrenDims = pdf.getTextDimensions(students);

      pdf.text(`Lien de connexion :`, x + 10, y + 56 + textChildrenDims.h);
      pdf.text(
        `https://parent.edtrust.tn/ \nou application Mobile EdParent`,
        x + 39,
        y + 56 + textChildrenDims.h
      );
      pdf.text(
        `Nom d'utilisateur : ${parent.userName}`,
        x + 10,
        y + 64 + textChildrenDims.h + 3
      );
      pdf.text(
        `Mot de passe : ${atob(parent.pp)}`,
        x + 10,
        y + 72 + textChildrenDims.h + 3
      );

      // Add QR code

      await new AwesomeQR({
        text: qrData,
        logoImage: logo,
        size: 200,
        correctLevel: 1,
        logoScale: 0.2,
        logoMargin: 0,
        logoCornerRadius: 8,
        whiteMargin: false,
        dotScale: 1,
        autoColor: true,
        binarize: false,
        binarizeThreshold: 128,
        backgroundColor: "#FFFFFF",
        margin: 3,
      })
        .draw()
        .then((dataURL) => {
          pdf.setPage(Math.floor(index / 4) + 1);

          pdf.addImage(dataURL, "JPEG", x + 33, y + 90, 40, 40);
          this.addImageToSection(
            pdf,
            x + sectionWidth - imageWidth - 5,
            y + 5,
            imageWidth,
            imageHeight,
            imgData
          );
        });
    },
    async generateLoginsParents() {
      await apiClient.get("/parents/parentslogins").then(async (res) => {
        const parentsLogins = res.data;
        this.loadingBadges = true;
        const pdf = new JsPDF();
        pdf.setFont("Amiri");
        pdf.setFontSize(10);
        let arrayOfImages = this.settings.image;
        for (var i = 0; i < arrayOfImages.length; i++) {
          if (this.settings.activeBuilding === arrayOfImages[i].db)
            var imgSrc = arrayOfImages[i].logo;
        }

        const schoolDetails = this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0];

        const schoolName = schoolDetails.ArabicName || schoolDetails.name;

        let imgData = "data:image/jpeg;base64, ";

        await axios
          .get(imgSrc, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            imgData += Buffer.from(response.data, "binary").toString("base64");
          });

        let logo = "data:image/jpeg;base64, ";

        await axios
          .get("favicon.png", {
            responseType: "arraybuffer",
          })
          .then((response) => {
            logo += Buffer.from(response.data, "binary").toString("base64");
          });

        let promises = [];
        // Iterate through parent data
        parentsLogins.forEach((parent, index) => {
          // Check if a new page is needed
          this.addPageWithSections(pdf, index);
          promises.push(
            this.drawSection(pdf, parent, index, logo, imgData, schoolName)
          );
        });
        // Save or display the PDF
        await Promise.all(promises)
          .then((values) => {
            //page numbering
            /* const pages = pdf.internal.getNumberOfPages();
            const pageWidth = pdf.internal.pageSize.width;
            const pageHeight = pdf.internal.pageSize.height;
            pdf.setFontSize(8); //Optional

            for (let j = 1; j < pages + 1; j++) {
              let horizontalPos = pageWidth / 2;
              let verticalPos = pageHeight - 5;
              pdf.setPage(j);
              pdf.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
                align: "center",
              });
            }*/

            this.loadingBadges = false;
            pdf.save("Logins parents.pdf");
          })
          .catch((reason) => {
            console.log(reason);
          });
      });
    },

    async drawBadge(doc, x, y, imgData, logo, student, pageNumber, schoolName) {
      doc.setPage(pageNumber);
      var today = new Date();
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      doc.setFont("Amiri");
      doc.setFontSize(10);
      let birthDay = new Date(student.BirthDate);
      doc.setFont("Amiri", "Bold");
      doc.text(47 + x, 21 + y, "Nom et prénom: ");
      doc.setFont("Amiri", "normal");
      doc.setFontSize(9);
      doc.text(
        47 + x,
        27 + y,
        this.capitalizeFirstLetter(student.firstName + " " + student.lastName)
      );
      doc.setFontSize(10);
      doc.setFont("Amiri", "Bold");
      doc.text(47 + x, 33 + y, "Date de naissance:");
      doc.setFont("Amiri", "normal");
      doc.text(47 + x, 39 + y, birthDay.toLocaleDateString("fr-FR", options));
      doc.setFontSize(5);
      doc.text(
        52 + x,
        47 + y,
        "Géneré le : " + " " + today.toLocaleDateString("fr-FR", options)
      );

      let qrDataDetail =
        student._id +
        "." +
        this.settings.activeBuilding +
        "." +
        this.settings.activeSchoolarYear +
        "." +
        this.settings.base_url;

      const qrData = btoa(qrDataDetail);

      await new AwesomeQR({
        text: qrData,
        logoImage: logo,
        size: 200,
        correctLevel: 1,
        logoScale: 0.2,
        logoMargin: 0,
        logoCornerRadius: 8,
        whiteMargin: false,
        dotScale: 1,
        autoColor: true,
        binarize: false,
        binarizeThreshold: 128,
        bindElement: true,
        backgroundColor: "#FFFFFF",
        margin: 3,
      })
        .draw()
        .then((dataURL) => {
          doc.setPage(pageNumber);
          doc.addImage(dataURL, "JPEG", 7 + x, 15 + y, 30, 30);
          doc.addImage(imgData, "JPEG", 75 + x, 6 + y, 10, 10);
          doc.setFontSize(13);
          doc.text(schoolName, 46 + x, 11 + y, "center");

          doc.setFillColor(0, 0, 0);
          doc.rect(4 + x, 4 + y, 84, 48, "S");
        });
    },
    async drawNewBadge(
      doc,
      x,
      y,
      imgData,
      logo,
      student,
      pageNumber,
      schoolName,
      defaultStudentPhoto,
      classroom
    ) {
      doc.setPage(pageNumber);

      doc.setLineWidth(1);

      const backgroundColor = "#81b6f7";
      const lineColor = "#61a6fa";
      const photoColor = "#ffffff";

      const page_width = doc.internal.pageSize.getWidth() / 2;
      const page_height = doc.internal.pageSize.getHeight() / 2;
      const ctx = doc.context2d;

      doc.setFillColor(backgroundColor);
      doc.setDrawColor(lineColor);

      doc.rect(x + 0, y + 50, page_width, page_height - 50, "FD");

      doc.setFillColor(photoColor);
      doc.setDrawColor(lineColor);
      doc.setLineWidth(0.6);
      doc.circle(x + page_width / 2, y + 50, 20, "FD");

      // draw student photo
      // -------------------------------------------------------------
      const studentPhoto = new Image();
      if (student.photo)
        studentPhoto.src = this.settings.base_url + "/" + student.photo;

      let imageSize = 39; // Adjust this to the desired image size
      let centerX = x + page_width / 2; // Adjust this to the desired X coordinate
      let centerY = y + 50; // Adjust this to the desired Y coordinate

      // Create a circular clipping path using context2d
      ctx.save();
      ctx.beginPath();
      ctx.arc(centerX, centerY, imageSize / 2, 0, Math.PI * 2);
      ctx.clip();

      // Draw the original image within the circular clipping path
      ctx.drawImage(
        student.photo ? studentPhoto : defaultStudentPhoto,
        centerX - imageSize / 2,
        centerY - imageSize / 2,
        imageSize,
        imageSize
      );

      // Reset the clipping path
      ctx.restore();
      ctx.closePath();
      // -------------------------------------------------------------

      doc.setFont("Amiri", "Bold");
      doc.setFontSize(22);
      doc.setTextColor("#ffffff");

      doc.text(
        x + page_width / 2,
        y + 78,
        this.capitalizeFirstLetter(student.firstName + " " + student.lastName),
        { align: "center" }
      );
      doc.setFont("Amiri", "normal");
      doc.setFontSize(15);
      doc.text(x + page_width / 2, y + 88, classroom, {
        align: "center",
      });

      let qrDataDetail =
        student._id +
        "." +
        this.settings.activeBuilding +
        "." +
        this.settings.activeSchoolarYear +
        "." +
        this.settings.base_url;

      const qrData = btoa(qrDataDetail);

      await new AwesomeQR({
        text: qrData,
        logoImage: logo,
        size: 200,
        correctLevel: 1,
        logoScale: 0.2,
        logoMargin: 0,
        logoCornerRadius: 8,
        whiteMargin: false,
        dotScale: 1,
        autoColor: true,
        binarize: false,
        binarizeThreshold: 128,
        bindElement: true,
        backgroundColor: "#FFFFFF",
        margin: 3,
      })
        .draw()
        .then((dataURL) => {
          doc.setPage(pageNumber);
          doc.addImage(
            dataURL,
            "PNG",
            x + (page_width / 2 - 17.5),
            y + page_height / 1.5,
            35,
            35
          );

          // logo and school name
          // -------------------------------------------------------------
          imageSize = 15;
          const text = schoolName;
          centerX = page_width / 2;
          centerY = y + 15;
          const padding = 5;
          doc.setFontSize(13);

          const totalWidth = imageSize + padding + doc.getTextWidth(text);
          const startX = x + centerX - totalWidth / 2;

          doc.addImage(
            imgData,
            "JPEG",
            startX,
            centerY - imageSize / 2,
            imageSize,
            imageSize
          );

          doc.setTextColor("#000000");

          doc.text(startX + imageSize + padding, centerY, text);
          doc.setFillColor(0, 0, 0);
          doc.setLineWidth(0.5);
          doc.rect(x, y, page_width, page_height, "S");
          // -------------------------------------------------------------
        });
    },
    async generateBadges() {
      this.$gtag.event("Imprimer les badges ", {
        event_category: "Impression PDF",
        event_label: "Liste des élèves section",
        value: 1,
      });

      this.loadingBadges = true;
      const doc = new JsPDF();
      const pdf_height = doc.internal.pageSize.height;
      const pdf_width = doc.internal.pageSize.width;

      doc.setFont("Amiri");

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db)
          var imgSrc = arrayOfImages[i].logo;
      }

      const schoolDetails = this.user.building.filter(
        (el) => el.dbName == this.settings.activeBuilding
      )[0];

      const schoolName = schoolDetails.ArabicName || schoolDetails.name;

      let imgData = "data:image/jpeg;base64, ";

      await axios
        .get(imgSrc, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          imgData += Buffer.from(response.data, "binary").toString("base64");
        });

      const data = {};
      let logo = "data:image/jpeg;base64, ";

      await axios
        .get("favicon.png", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          logo += Buffer.from(response.data, "binary").toString("base64");
        });

      this.filtredTable.forEach((student) => {
        if (!data[student.classRoom]) data[student.classRoom] = [];
        data[student.classRoom].push(student);
      });

      let y = 0;
      let left = true;
      let firstPage = true;
      let currentPage = 1;

      // array for optimization
      const promises = [];

      for (const classroom of Object.keys(data)) {
        doc.setFontSize(17);
        y = 15;
        if (!firstPage) {
          doc.addPage();
          currentPage++;
          left = true;
        }
        const width = doc.getTextWidth(classroom);
        doc.text(classroom, pdf_width / 2 - width / 2, 10);
        const classroomData = data[classroom];
        for (let i = 0; i < classroomData.length; i++) {
          if (left)
            promises.push(
              this.drawBadge(
                doc,
                16,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage,
                schoolName
              )
            );
          else
            promises.push(
              this.drawBadge(
                doc,
                100,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage,
                schoolName
              )
            );

          if (!left) {
            y += 48.1;
            if (y + 68.1 >= pdf_height) {
              y = 0;
              left = false;
              doc.addPage();
              currentPage++;
            }
          }

          left = !left;
        }

        firstPage = false;
      }

      await Promise.all(promises)
        .then((values) => {
          //page numbering
          const pages = doc.internal.getNumberOfPages();
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;
          doc.setFontSize(8); //Optional

          for (let j = 1; j < pages + 1; j++) {
            let horizontalPos = pageWidth / 2;
            let verticalPos = pageHeight - 5;
            doc.setPage(j);
            doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
              align: "center",
            });
          }

          this.loadingBadges = false;
          doc.save("Liste des badges.pdf");
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
    async generateNewBadges() {
      this.$gtag.event("Imprimer les badges avec photos", {
        event_category: "Impression PDF",
        event_label: "Liste des élèves section",
        value: 1,
      });

      this.loadingBadges = true;
      const doc = new JsPDF();
      const pdf_height = doc.internal.pageSize.height;
      const pdf_width = doc.internal.pageSize.width;

      doc.setFont("Amiri");

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db)
          var imgSrc = arrayOfImages[i].logo;
      }

      const schoolDetails = this.user.building.filter(
        (el) => el.dbName == this.settings.activeBuilding
      )[0];

      const schoolName = schoolDetails.ArabicName || schoolDetails.name;

      let imgData = "data:image/jpeg;base64, ";

      await axios
        .get(imgSrc, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          imgData += Buffer.from(response.data, "binary").toString("base64");
        });

      const data = {};
      let logo = "data:image/jpeg;base64, ";

      await axios
        .get("favicon.png", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          logo += Buffer.from(response.data, "binary").toString("base64");
        });

      let defaultStudentPhoto = "data:image/jpeg;base64, ";

      await axios
        .get("/resources/images/blank.png", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          defaultStudentPhoto += Buffer.from(response.data, "binary").toString(
            "base64"
          );
        });

      this.filtredTable.forEach((student) => {
        if (!data[student.classRoom]) data[student.classRoom] = [];
        data[student.classRoom].push(student);
      });

      let y = 0;
      let left = true;
      let firstPage = true;
      let currentPage = 1;

      // array for optimization
      const promises = [];

      for (const classroom of Object.keys(data)) {
        doc.setFontSize(17);
        y = 0;
        if (!firstPage) {
          doc.addPage();
          currentPage++;
          left = true;
        }
        const classroomData = data[classroom];
        for (let i = 0; i < classroomData.length; i++) {
          if (left)
            promises.push(
              this.drawNewBadge(
                doc,
                0,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage,
                schoolName,
                defaultStudentPhoto,
                classroom
              )
            );
          else
            promises.push(
              this.drawNewBadge(
                doc,
                pdf_width / 2,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage,
                schoolName,
                defaultStudentPhoto,
                classroom
              )
            );

          if (!left) {
            y += pdf_height / 2;
            if (y + 2 >= pdf_height) {
              y = 0;
              left = false;
              doc.addPage();
              currentPage++;
            }
          }

          left = !left;
        }

        firstPage = false;
      }

      await Promise.all(promises)
        .then((values) => {
          this.loadingBadges = false;
          doc.save("Liste des badges.pdf");
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
    generatePdf() {
      this.$gtag.event("Imp tab listeElèves", {
        event_category: "Impression PDF",
        event_label: "Liste des élèves section",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Liste des élèves";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 65, titre);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(
          this.filtredTable[i].uniqueId ? this.filtredTable[i].uniqueId : "--"
        );

        arr.push(this.filtredTable[i].firstName);
        arr.push(this.filtredTable[i].lastName);
        arr.push(this.filtredTable[i].scolarite);
        arr.push(this.filtredTable[i].adress);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        margin: { top: 70 },
        head: [["Identifiant unique", "Prénon", "Nom", "scolarité", "Adress"]],
        body: array,
        foot: [
          [
            { colSpan: 3, content: "Total", styles: { halign: "center" } },
            {
              colSpan: 2,
              content: this.filtredTable.length,
              styles: { halign: "center" },
            },
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Liste_des_élèves.pdf");
    },
    attestationInscriptionPDF(record) {
      this.$gtag.event("Imp attestation d'inscription", {
        event_category: "Impression PDF",
        event_label: "Liste des élèves section",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      if (record) {
        var titre = "Attestation d'inscription";
        var string = `Je soussigné  ${
          this.activeBuilding.headMaster
        } le directeur de l'école ${
          this.activeBuilding.name
        }  atteste \n \nque l'élève ${
          record.firstName + " " + record.lastName
        } né(e) le ${
          record.BirthDate
        }  est insécrit(e) au sein \n \nde notre etablissement pour l'année scolaire ${
          this.currentYear
        }.`;
        var signature = "Signature";
        doc.setFontSize(30);
        doc.text(titre, 55, 90);
        doc.setFontSize(15);
        doc.text(string, 20, 110);
        doc.setFontSize(15);
        doc.text(signature, 150, 165);
      }
      doc.save("attestation d'inscription.pdf");
    },
    //arabe
    async attestationPresencePDF() {
      this.$gtag.event("Imp ar attestation de présence", {
        event_category: "Impression PDF",
        event_label: "Liste des élèves section",
        value: 1,
      });

      this.loadingData = true;

      this.visibleAttestationPresence = false;

      const pdfData = {
        commissariat: $("#cr").val(),
        nomStudent: $("#nomStudent").val(),
        BirthDate: this.attestationPresenceBirthdate,
        className: $("#className").val(),
        reason: $("#reason").val(),
        recuEn: $("#recuEn").val(),
        headMaster: $("#dir").val(),
      };

      let stamp = "";
      await apiClient
        .get("/buildings/" + this.settings.activeBuildingId + "/stamp")
        .then((res) => {
          stamp = this.settings.base_url + "/" + res.data.imageURL;
        })
        .catch((e) => {
          console.log(e);
        });

      const stampImage = new Image();
      if (stamp) stampImage.src = stamp;

      const doc = new JsPDF();
      const pdfWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Amiri");
      let date = new Date();

      const t1 = "الجمهورية التونسية";
      const t2 = "وزارة التربية";
      const t3 = "المندوبية الجهوية للتربية";
      const t4 = pdfData.commissariat;
      const t5 = "المدرسة الابتدائية";

      let width = doc.getTextWidth(t1);
      let nwidth = 0;
      doc.text(t1, pdfWidth - 70 + (25 - width / 2), 10);
      width = doc.getTextWidth(t2);
      doc.text(t2, pdfWidth - 70 + (25 - width / 2), 20);
      width = doc.getTextWidth(t3);
      doc.text(t3, pdfWidth - 70 + (25 - width / 2), 30);
      width = doc.getTextWidth(t4);
      doc.text(t4, pdfWidth - 70 + (25 - width / 2), 40);

      width = doc.getTextWidth(t5);
      doc.text(t5, 80 - (25 + width / 2), 20);
      width = doc.getTextWidth(this.schoolDetails.ArabicName);
      doc.text(this.schoolDetails.ArabicName, 80 - (25 + width / 2), 27);

      doc.setFontSize(12);
      const titre = "شهادة حضور";
      const headMaster = " : (1) يشهد مدير";
      const student = " : (أن التلميذ )ة";
      date = new Date(pdfData.BirthDate)
        .toISOString()
        .split("T")[0]
        .replace(/\-/g, "/");
      const birth = date + " : المولود )ة( في";
      const inscriIn = `(مرسّم )ة( بالمدرسة */ المعهد المذكور )ة(* أعلاه و يزاول دراسته )ها`;
      const inscriIn2 = `(2)ب`;
      const reasonTitle = ": سلّمت هذه الشهادة للمعني بالأمر )ة( قصد";
      const reason = pdfData.reason;

      date = new Date().toISOString().split("T")[0].replace(/\-/g, "/");
      const signedIn = `${date} حرّر بـ ${pdfData.recuEn} في`;

      const signature = "الإمضاء و الختم";
      const headmasterSignature = "(3) (المدير )ة";

      const indication1 = "____________________";
      const indication2 = "بيان إسم المؤسسة التربوية";
      const indication3 = "يذكر الفصل بلسان القلم";
      const indication4 = "يذكر الإسم و اللقب و الصفة";
      const indication5 = "يشطب الزائد (*)";

      doc.setFontSize(30);
      width = doc.getTextWidth(titre);
      doc.text(titre, pdfWidth / 2 - width / 2, 90);
      doc.setFontSize(15);

      width = doc.getTextWidth(headMaster);
      doc.text(headMaster, pdfWidth - width - 20, 110);

      nwidth = doc.getTextWidth(pdfData.headMaster);
      doc.text(pdfData.headMaster, pdfWidth - width - nwidth - 20, 110);

      width = doc.getTextWidth(student);
      doc.text(student, pdfWidth - width - 20, 110 + 10);

      nwidth = doc.getTextWidth(pdfData.nomStudent);
      doc.text(pdfData.nomStudent, pdfWidth - width - nwidth - 20, 110 + 10);

      width = doc.getTextWidth(birth);
      doc.text(birth, pdfWidth - width - 20, 110 + 20);

      width = doc.getTextWidth(inscriIn);
      doc.text(inscriIn, pdfWidth - width - 20, 110 + 30);
      width = doc.getTextWidth(inscriIn2);
      doc.text(inscriIn2, pdfWidth - width - 20, 110 + 40);

      nwidth = doc.getTextWidth(pdfData.className);
      doc.text(pdfData.className, pdfWidth - width - nwidth - 20, 110 + 40);

      width = doc.getTextWidth(reasonTitle);
      doc.text(reasonTitle, pdfWidth - width - 20, 110 + 50);
      doc.setFontSize(13);
      width = doc.getTextWidth(reason);
      doc.text(reason, pdfWidth - width - 26, 110 + 60);
      doc.setFontSize(15);
      width = doc.getTextWidth(signedIn);
      doc.text(signedIn, pdfWidth / 2 - width / 2, 200);
      width = doc.getTextWidth(signature);
      doc.text(signature, pdfWidth / 2 - width / 2, 210);
      width = doc.getTextWidth(headmasterSignature);
      doc.text(headmasterSignature, pdfWidth / 2 - width / 2, 220);

      doc.setFontSize(10);

      width = doc.getTextWidth(indication1);
      doc.text(indication1, pdfWidth - width - 20, 250);

      width = doc.getTextWidth("(1)");
      doc.text("(1)", pdfWidth - width - 20, 255);
      nwidth = doc.getTextWidth(indication2);
      doc.text(indication2, pdfWidth - width - nwidth - 20, 255);

      width = doc.getTextWidth("(2)");
      doc.text("(2)", pdfWidth - width - 20, 260);
      nwidth = doc.getTextWidth(indication3);
      doc.text(indication3, pdfWidth - width - nwidth - 20, 260);

      width = doc.getTextWidth("(3)");
      doc.text("(3)", pdfWidth - width - 20, 265);
      nwidth = doc.getTextWidth(indication4);
      doc.text(indication4, pdfWidth - width - nwidth - 20, 265);

      width = doc.getTextWidth(indication5);
      doc.text(indication5, pdfWidth - width - 20, 270);

      if (stamp) {
        try {
          await this.waitLoadingImage(stampImage);
          doc.addImage(stampImage, "JPEG", pdfWidth / 2 - 20, 230, 40, 40);
        } catch (e) {}
      }

      this.loadingData = false;
      doc.save("attestation_présence.pdf");
    },
    async attestationPresenceFr(record) {
      this.$gtag.event("Imp fr attestation de présence", {
        event_category: "Impression PDF",
        event_label: "Liste des élèves section",
        value: 1,
      });

      this.loadingData = true;

      this.visibleAttestationPresence = false;

      const pdfData = {
        commissariat: $("#cr").val(),
        nomStudent: $("#nomStudent").val(),
        BirthDate: this.attestationPresenceBirthdate,
        className: $("#className").val(),
        reason: $("#reason").val(),
        recuEn: $("#recuEn").val(),
        headMaster: $("#dir").val(),
      };

      let stamp = "";
      await apiClient
        .get("/buildings/" + this.settings.activeBuildingId + "/stamp")
        .then((res) => {
          stamp = this.settings.base_url + "/" + res.data.imageURL;
        })
        .catch((e) => {
          console.log(e);
        });
      const stampImage = new Image();
      if (stamp) stampImage.src = stamp;
      const doc = new JsPDF();
      doc.setFont("Amiri");

      var date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      if (record) {
        var titre = "Attestation de présence";
        var string = `Je soussigné M. ${pdfData.headMaster}  le directeur de l'école ${this.schoolDetails.name}  \n \n atteste que l'élève ${pdfData.nomStudent} né(e) le ${pdfData.BirthDate} est présent(e) \n \n au sein de notre etablissement pour l'année scolaire ${this.currentYear}.`;
        var signature = "Signature";
        doc.setFontSize(30);
        doc.text(titre, 55, 90);
        doc.setFontSize(15);
        doc.text(string, 20, 110);
        doc.setFontSize(15);
        doc.text(signature, 150, 165);
      }
      if (stamp) {
        try {
          await this.waitLoadingImage(stampImage);
          doc.addImage(stampImage, "JPEG", 150, 170, 40, 40);
        } catch (e) {}
      }

      this.loadingData = false;
      doc.save("attestation_présence.pdf");
    },
    waitLoadingImage(image) {
      return new Promise((res, rej) => {
        image.onload = (t) => {
          res();
        };
        image.onerror = (e) => {
          rej();
        };
      });
    },

    attestationReussitePDF(record) {
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Attestation de réussite";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(30);
      doc.text(titre, 55, 90);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      if (record) {
        var titre = "Attestation de réussite";
        var string = `Je soussigné ${
          this.activeBuilding.headMaster
        } le directeur de l'école ${
          this.activeBuilding.dbName
        }  \n \n atteste que l'élève ${
          record.firstName + " " + record.lastName
        } né(e) le ${
          record.BirthDate
        } est présent(e) \n \n au sein de notre etablissement pour l'année scolaire ${
          this.currentYear
        }.`;
        var signature = "Signature";
        doc.setFontSize(30);
        doc.text(titre, 55, 90);
        doc.setFontSize(15);
        doc.text(string, 20, 110);
        doc.setFontSize(15);
        doc.text(signature, 150, 165);
      }
      doc.save("attestation_réussite.pdf");
    },
    showModal() {
      this.visibleData = true;
      this.newStudentForm.resetFields();
    },
    onChange(e) {
      this.newStudent.gender = e.target.value;
    },
    onChangeParent(e) {},
    openNotificationWithIcon(type, msg, desc) {
      notification[type]({
        message: msg,
        description: desc,
      });
    },
    handleParentsSuggestions(val) {
      if (this.suggestionTimeout) clearTimeout(this.suggestionTimeout);

      let parentsList = [];

      if (val.target.value) {
        parentsList = this.parentsList.filter(
          (e) =>
            e.fatherPhone == val.target.value ||
            e.motherPhone == val.target.value
        );
      }

      if (parentsList.length > 0) {
        this.suggestionTimeout = setTimeout(() => {
          this.suggestionParentList = parentsList;
          this.selectedSuggestionParent = this.suggestionParentList[0];
          this.visibleParentSuggestion = true;
        }, 700);
      } else {
        this.visibleParentSuggestion = false;
      }
    },
    handleAddParentSuggestion() {
      this.newStudentForm.setFieldsValue({
        parent: this.selectedSuggestionParent.id,
      });
      this.visibleParentSuggestion = false;
      clearTimeout(this.suggestionTimeout);
      setTimeout(() => {
        this.disabledSP = !this.disabledSP;
        this.newParent = !this.newParent;
      }, 100);
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.border-with-text {
  position: relative;
  border: 2px solid #95a3b3;
  padding: 1rem;
}

.border-with-text .border-text {
  position: absolute;
  top: -12px;
  left: 20px;
  background-color: white;
  padding: 0 10px;
  font-weight: bold;
}
::v-deep .ant-list-item {
  margin-bottom: 0px !important;
}
</style>
